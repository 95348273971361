<template>
  <div id="app">
      <!-- <waterfall :line-gap="200" :watch="items"> -->
        <!-- each component is wrapped by a waterfall slot -->
      <el-row>
        <el-alert
          title="点开图片，长按保存图片到相册"
          type="info"
          style="margin-bottom: 10px">
        </el-alert>
      </el-row>

      <el-row>

      <el-col :span="18">
        <el-input v-model="input" placeholder="请输入拍照日期查询：20210520" @keyup.enter.native="search()"></el-input>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="search()">搜索</el-button>
      </el-col>

    </el-row>
    <el-divider></el-divider>
    <waterfall :col=2 :data="items" v-loading.fullscreen.lock="loading">
      <template>
          <div class="cell-item" v-for="(item, index) in items" :key="index">

            <el-card class="box-card" shadow="hover">
              <el-image :src="item.src" :preview-src-list="item.srcList">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
              <!-- <div style="padding: 5px; font-size: 12px">
                <div class="bottom clearfix">
                  <time class="time">{{ item.name }}</time>
                </div>
              </div> -->
            </el-card>
        </div>
      </template>
    </waterfall>

    <!--     <waterfall-slot
          v-for="(item, index) in items"
          :width="item.width"
          :height="item.height"
          :order="index"
          :key="item.id"
        > -->
 <!--          <el-image :src="item.src">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </waterfall-slot>
      </waterfall> -->
  </div>
</template>

<script>
import axios from 'axios'
import OSS from 'ali-oss'
import waterfall from 'vue-waterfall2'

export default {
    data () {
        return {
            input: '',
            searchVal: '',
            items: [],
            loading: false
        }
    },
    mounted() {
      let _self = this
      this.$nextTick(() => {
        window.onscroll = function() {
          var scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
          var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
          var scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
          if(scrollTop+windowHeight==scrollHeight){
            _self.loading = true
            _self.getData('', _self.items[_self.items.length - 1].name)
          }  
        }
      })
    },
    methods: {
      getData(prefix = '', name = '') {
        const client = new OSS({
          region: 'oss-cn-shanghai',
          accessKeyId: 'LTAI5tJvQ3F87xXJ1qyuTqM6',
          accessKeySecret: 'ANCnFej1XGWIjn8YgahxSUokAJjDbt',
          bucket: 'jmzt',
          stsToken: ''
        });

        client.listV2({
          prefix: prefix ? prefix : 'sort/',
          'max-keys': 10,
          'start-after': name,
        }).then((result) => {
            if (result.keyCount == 0) {
              this.$notify.info({
                title: '提示',
                message: '没有更多图片了',
              });
              this.loading = false
              return
            }
            let data = []
            console.log(result.objects)
            for (let row of result.objects) {
                if (row.size == 0) {
                  continue
                }
                data.push({
                    src: row.url + '?x-oss-process=style/small',
                    srcList: [row.url],
                    name: row.name,
                    id: row.etag,
                    width: 100,
                    height: 200,
                })
            }
            this.items = this.items.concat(data)
            this.loading = false
        })
      },
      search() {
        this.searchVal = this.input
        this.items = []
        this.getData('search/' + this.searchVal)
      }
    },
    created() {
      this.getData()
    }
}
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
